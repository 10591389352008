<template>
    <div> 
        <div class="row my-0">
            <div class="col-12">
                <h1 class="mb-4">{{ SHARED.SupportCenterPage.Videos.Title }}</h1>
            </div>
        </div>

        <div v-if="enableEdition" class="row">
            <div class="col-12 mb-5">
                <div class="mb-3">
                    <h3>{{SHARED.SupportCenterPage.Videos.AddNewVideosTitle}}</h3> 
                </div>

                <video-item
                    :is-new="true"
                    :videos="emptyVideoSchema"
                    @handle-items="handleItem"
                />
            </div>

            <div v-if="this.videoSchema.length > 0" class="col-12 mb-3">
                <div class="d-flex align-items-center">
                    <h3>{{SHARED.SupportCenterPage.Videos.ExistingVideosTitle}}</h3>
                    <span class="ml-2">{{SHARED.SupportCenterPage.Videos.Subtitle}}</span>
                </div>
                <recess-divider show-line class="pt-0"/>
           
                <recess-card variant="variant2" class="p-2 mt-2">
                    <video-item
                        :videos="videoSchema"
                        @handle-items="handleItem"
                    />
                </recess-card>
            </div>  
        </div>

        <div class="d-flex flex-wrap" v-else> 
            <div
                class="col-6 mb-5"
                v-for="(videoThumb, index) in videoSchema"
                :key="`video-thumb-${index}`"
            >
                <video-player :videoThumb="videoThumb"></video-player>
            </div>
        </div>
    </div>
</template>

<script>
// Assets
import iconPlay from '../../../src/assets/images/play.svg'
import { SHARED } from "../../../constants/EdumsSharedConstants.json";
import UserService from '../../../services/UserService'  
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { postItem, deleteItem, putItem, getItem } from '@/../../shared/api/SharedClient'

// Components
import VideoItem from './VideoItem.vue'
import VideoPlayer from './VideoPlayer.vue';

// Mixins
import toastNotification from '../../../mixins/toastNotificationMixin'
import { videoErrorAction } from '../../../constants/validationMessageHelper';

export default {
    name: "Videos",
    components: {
        VideoItem,
        VideoPlayer
    },
    mixins: [toastNotification],
    data() {
        return {
            userService: new UserService(),
            isAdministrator: false,
            iconPlay,
            SHARED,
            emptyVideoSchema: [
                {
                    title: null,
                    url: null,
                    thumbnailUrl: null
                }
            ],
            videoSchema: []
        }
    },
    async created() {
        this.isAdministrator = await this.userService.isAdministrator() 
    },
    async mounted() {
        await this.getVideos()
    },
    computed: {
        enableEdition() {
            return this.isAdministrator && process.env.VUE_APP_OIDC_CLIENT_ID === 'edums_core'
        }
    },
    methods: {
        async getVideos() {
            try {
                const response = await getItem(
                process.env.VUE_APP_DEVOPS_API_URL,
                API_CALL_URL_PATHS.supportVideos
                )
                
                if (!response) return
                this.videoSchema = response

            } catch (error) {
                console.error('Something went wrong while retrieving the whats new', error)
            }
        },
        async postNewItems(objectToPost) {  
            await postItem(
                process.env.VUE_APP_DEVOPS_API_URL,
                API_CALL_URL_PATHS.supportVideos,
                objectToPost,
                false
            )
            .then(() => {  
                this.getVideos()
                this.toastMessage('success', SHARED.SupportCenterPage.Videos.Messages.Post.Success)
            }) 
            .catch(error => {
                this.toastMessage('error', SHARED.SupportCenterPage.Videos.Messages.Post.Error) 
                console.error('Something went wrong while posting whats new', error)
            })
        },
        async deleteNewItems(currentVideoId) {
            await deleteItem(
                process.env.VUE_APP_DEVOPS_API_URL,
                `${API_CALL_URL_PATHS.supportVideos}/${currentVideoId}`,
                false
            )
            .then(() => {
                this.getVideos() 
                this.toastMessage('success', SHARED.SupportCenterPage.Videos.Messages.Delete.Success)
            })
            .catch((error) => {
                this.toastMessage('error', videoErrorAction('verwijderd'))
                console.error('Something went wrong while deleting account', error)
            })
        },
        async putNewItems(objectToPut) {
            await putItem(
                process.env.VUE_APP_DEVOPS_API_URL,
                API_CALL_URL_PATHS.supportVideos,
                '',
                objectToPut,
                false
            )
            .then(() => {
                this.getVideos() 
               this.toastMessage('success', SHARED.SupportCenterPage.Videos.Messages.Put.Success)
            })
            .catch((error) => {

                // this.toastMessage('error', SHARED.SupportCenterPage.Videos.Messages.Put.Error)
                console.error('Something went wrong while deleting account', error)
            })
           
        },
        async handleItem(payload) {
            if(payload.itemToDelete) {  
                await this.deleteNewItems(payload.currentVideoId)  
            }

            if(payload.itemToPost) { 
                await this.postNewItems(payload.objectToPost)  
            }

            if(payload.itemToPut) {
                await this.putNewItems(payload.objectToPut)  

            }
        }
    }
}
</script>
