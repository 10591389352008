const mixin = {
    methods: {
        toastMessage(toastType, toastMessage) {
            const toastNotification = {
                type: toastType,
                message: toastMessage
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })
        }
    }
}

export default mixin