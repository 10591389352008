<script>
export default {
    // WARNING: does not support a 'router-link' root

    // This is a functional Vue component

    // This component renders the root element in the given slot conditionally, the rest of the slot content is always rendered
    // The value of the conditional property can be set in the 'shouldRenderRoot' property of this component
    // Example: https://jsfiddle.net/Linusborg/w9d8ujn8/

    functional: true,
    props: {
        shouldRenderRoot: {
            type: Boolean,
            default: false
        }
    },
    render(h, ctx) {
        // Remove unnecessary text nodes
        const children = ctx.children.filter(vnode => vnode.tag)
           
        if (children.length !== 1) {
            console.warn('This component accepts only one root node in its slot.')
        }

        if (ctx.props.shouldRenderRoot) {
            return children[0]
        }
        return children[0].componentOptions.children
    }
}
</script>
