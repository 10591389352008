<template>
    <div>
        <render-root-element :shouldRenderRoot="!isNew">
            <draggable ghost-class="video-ghost" class='d-flex flex-wrap row px-0 mx-0' :list="videos">
                <div :class="[ 'c-video__card mb-4', {'col-6 c-video__card--draggable' : !isNew}]"  v-for="(video, videoIndex) in videos" :key="videoIndex">
                    <recess-card variant="variant1">     
                        <div v-if="!isNew" class='d-flex justify-content-end mb-4'>
                            <span @click='removeVideo(video.id)' :class="`c-icon c-icon__bin qa-video-delete-button-${videoIndex}`"></span>
                        </div>

                        <div class='d-flex flex-wrap'>
                            <recess-input
                                v-model="video.title"
                                :label-text="SHARED.SupportCenterPage.Videos.Form.Labels.Title"
                                class="w-100 qa-video-title"
                                :error-message="titleRequired(videoIndex)" 
                                @blur="$v.videos.$each[videoIndex].title.$touch()"
                            />

                            <recess-input
                                v-model="video.url" 
                                :label-text="SHARED.SupportCenterPage.Videos.Form.Labels.Url"
                                class="w-100 mt-4 qa-video-url"
                                :error-message="urlErrorMessage(videoIndex)" 
                                @blur="$v.videos.$each[videoIndex].url.$touch()"
                            />

                            <recess-file-upload 
                                :label-text="SHARED.SupportCenterPage.Videos.Form.Labels.Thumbnail"
                                :placeholder-text="video.thumbnailUrl ? video.thumbnailUrl : SHARED.SupportCenterPage.Videos.Form.Placeholders.Thumbnail"
                                max-size-file="1044000"
                                :max-file-to-upload="1"
                                uploadedFileStyle="variant3"
                                :allowed-extensions="['png', 'jpg']"
                                class="w-100 mt-4 qa-support-video-file-upload"
                                @upload="(newValue) => uploadImage(video, newValue)"
                                ref="fileUpload"
                            />  
                        </div>

                        <div class='d-flex justify-content-end mt-3'>
                            <recess-button
                                v-if="isNew"
                                variant="secondary"
                                :title="SHARED.SupportCenterPage.Videos.Form.Actions.Add"
                                class="qa-save-video"
                                @click.native.prevent='addVideo(video)'
                            />
                        </div>                        
                    </recess-card> 
                </div>
            </draggable>
        </render-root-element>
        <div class='d-flex justify-content-end mb-3 px-3'>
            <recess-button
                v-if="!isNew"
                variant="secondary"
                :title="SHARED.Actions.Save"
                class="qa-update-video"
                @click.native.prevent='saveVideos()'
            />
        </div>
    </div>
</template>

<script>
// Assets
import { SHARED } from "../../../constants/EdumsSharedConstants.json";
import { thumbDefault } from '../../../src/assets/images/base64/video-thumb'

// Components
import draggable from 'vuedraggable'
import RenderRootElement from '../../../components/Shared/RenderRootElement.vue'

// Validations
import { isValidUrl } from '../../../utils/validationHelpers'
import { required } from 'vuelidate/lib/validators' 
import { isRequiredErrorMessage, videoErrorAction } from "../../../constants/validationMessageHelper.js";

// Mixins
import { validationMixin } from 'vuelidate'
import toastNotification from '../../../mixins/toastNotificationMixin'

export default {
    name: 'VideoItem',
    mixins: [ validationMixin, toastNotification ],
    components: {
        draggable,
        RenderRootElement
    },
    validations: {
        videos: { 
            $each: {
                title: { required },
                url: { required, isValidUrl }
            }
        }
    }, 
    props: {
        videos: {
            type: Array,
            default: [
                {
                    title: null,
                    url: null,
                    thumbnailUrl: null,
                    thumbnailBase64: null,
                    thumbnailName: null
                }
            ]
        },
        isNew: {
            type: Boolean, 
            default: false
        }
    },
    data() {
        return {
            SHARED,
            thumbDefault
        }
    },
    methods: {
        removeVideo(currentVideoId) {  
            this.$emit('handle-items', {itemToDelete: true, currentVideoId})
        },
        addVideo(newVideo) {
            this.$v.$touch()

            if(this.$v.$invalid) {
              this.toastMessage('error', videoErrorAction('toegevoegd'))

                return
            }
          
            if(!newVideo.id) {
                const objectToPost = {
                    title: newVideo.title,
                    url: newVideo.url,
                    thumbnail: newVideo.thumbnail ? newVideo.thumbnail : thumbDefault,
                    thumbnailName: newVideo.thumbnailName ? newVideo.thumbnailName : 'default'
                } 

                this.$emit('handle-items', {itemToPost: true, objectToPost})  

                this.$v.$reset() 
                newVideo.thumbnailName = null
            } 
        },
        async saveVideos() { 
            this.$v.$touch()

            if(this.$v.$invalid) {
                
                this.toastMessage('error', videoErrorAction('geüpdatet', true))
            
                return
            }

            const objectToPut = JSON.parse(JSON.stringify(this.videos))
            objectToPut.map(video => {
                video.thumbnail = video.thumbnailBase64
                video.thumbnailBase64 = null
            })

            this.$emit('handle-items',  { itemToPut: true, objectToPut })
                 
        },
        titleRequired(index) {  
            return isRequiredErrorMessage('title', 'Titel', this.$v.videos.$each[index].title)
        },
        urlErrorMessage(index) {
            if (this.$v.videos.$each[index].url.$error && !this.$v.videos.$each[index].url.isValidUrl ) {
                return SHARED.ErrorMessages.Url.Invalid
            }
            
            return isRequiredErrorMessage('url', 'Url', this.$v.videos.$each[index].url)
        },
        dynamicImage(image, imageUrl) { 
            return image ? `data:image/png;base64,${image}` : imageUrl
        },
        uploadImage(video, image) { 
            if (image && image[0]) {
                //The thumbnail base64 we sent to the back end
                video.thumbnail = image[0].base64FileString
                //The property we will use to display the url on the UI
                video.thumbnailUrl = null
                //The thumbnail name we sent to the back end to be able to delete images
                video.thumbnailName = image[0].structure.name.split('.')[0]

                this.dynamicImage(video.thumbnail, video.thumbnailUrl)
            } else {
                video.thumbnail = null
            }
        }
    }
}
</script>
