<template>
    <div class="c-thumb">
        <h6 class="mb-2 u-font-weight-bold">{{ videoThumb.title }}</h6>
        <a @click="handleClick" class="c-thumb-item" v-if="!expanded">
            <img
                alt="icon play"
                :src="iconPlay"
                class="c-thumb__icon-play"
            />
            <img
                alt="video thumb"
                :src="videoThumb.thumbnailUrl"
                class="c-thumb__image w-100"
            />
        </a>
        <div v-else v-html="videoThumb.embedCode"></div>
    </div>
</template>

<script>
import iconPlay from '../../../src/assets/images/play.svg';

export default {
    props: {
        videoThumb: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            expanded: false,
            iconPlay: iconPlay
        };
    },
    methods: {
        handleClick() {
            if (this.videoThumb.embedCode) {
                this.expanded = !this.expanded;
            } else if (this.videoThumb.url) {
                window.open(this.videoThumb.url, '_blank');
            }
        }
    }
};
</script>
